<template>
  <main style="padding-top: 100px">
    <div class="page_header element_to_stick">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12">
            <div class="search_bar_list">
              <b-input
                type="text"
                @keyup.enter="search"
                v-model="searchTerm"
                class="form-control"
                placeholder="Cautare oras..."
              ></b-input>
              <button @click="search" type="submit">
                <i class="icon_search"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- /row -->
      </div>
    </div>
    <!-- /page_header -->

    <div class="container margin_30_20">
      <b-row class="cities-wrapper">
        <b-col md="4" sm="12" v-for="(city, index) in cities" :key="index">
          <div class="strip">
            <figure>
              <b-img :src="'/cities/' + city.slug + '.jpg'" fluid-grow></b-img>
              <router-link
                class="strip_info"
                :to="{
                  name: 'public.city.details',
                  params: { slug: city.slug },
                }"
              >
                <div class="item_title">
                  <h3>{{ city.name }}</h3>
                </div>
              </router-link>
            </figure>
          </div>
        </b-col>
      </b-row>
    </div>
  </main>
</template>

<script>
import PublicCities from "@/components/publicEndpoints/cities";

export default {
  name: "Cities",
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      cities: [],
      paging: {
        page: 1,
        per_page: 12,
        total: 10,
        lastPage: 1000
      },
      filters: {},
      searchTerm: this.$route.params.searchTerm || "",
      lazyLoadingBusy: false,
      endOfResults: false,
      isBusy: false,
    };
  },
  methods: {
    search() {
      this.paging.search = this.searchTerm;
      /*
      this.filters = {
        slug: this.searchTerm
      }
       */
      this.getCities(true);
    },
    getCities(clearResults = false) {
      if (this.endOfResults || this.isBusy) {
        return;
      }
      this.isBusy = true;
      PublicCities.getAll(this.paging, this.filters).then((response) => {
        if (response.data.data.length === 0) {
          this.endOfResults = true;
        }
        if (clearResults) {
          this.cities = response.data.data;
        } else {
          response.data.data.forEach((city) => {
            if (!this.cityExists(city.id)) {
              this.cities.push(city);
            }
          });
        }
        this.paging.total = response.data.meta.total;
        this.paging.lastPage = response.data.meta.last_page;
        this.isBusy = false;
        this.lazyLoadingBusy = false;
      });
    },
    handleScroll: function (e) {
      if (this.lazyLoadingBusy) {
        return;
      }
      const bodyHeight = e.target.body.offsetHeight;
      const innerHeight = window.innerHeight;
      const scrollY = window.scrollY;
      const beforeBottom = document.querySelector('.cities-wrapper').offsetTop;
      const overSize = 200;
      if (innerHeight + scrollY + overSize >= bodyHeight - beforeBottom) {
        this.lazyLoadingBusy = true;
        this.paging.page++;
        this.getCities();
      }
    },
    cityExists(id) {
      return this.cities.some((el) => {
        return el.id === id;
      });
    },
  },
  mounted() {
    this.getCities(true);
  },
};
</script>
